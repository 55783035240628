.slide {
  display: inline-block;
  height: 100%;
}
.imagesContainer {
  height: 100%;
  > div {
    height: 100%;
  }
}

.dotContainer {
  width: 3cm;
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: -3vh;
  .dotSpace {
    padding: 3px;
    width: 33%;
    cursor: pointer;
    .active {
      background: var(--primary) !important;
    }
    .dot {
      margin: 0 auto;
      width: 100%;
      height: 10px;
      background: white;
      box-shadow: 1px 1px 5px #ccc;
      border-radius: 40px;
    }
    &:first-child .dot {
      width: 100%;
      float: left;
    }
    &:last-child .dot {
      float: right;
    }
  }
}
