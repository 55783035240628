.groupInputs {
  > div {
    align-self: center;
  }
  button {
  }
}
.error {
  color: red;
}
