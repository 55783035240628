.fullscreen {
  display: block;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.profile {
  height: 30px;
  width: 30px;
  border-radius: 100%;
}
.bigProfile {
  border-radius: 100%;
  height: 250px;
  width: 250px;
}
.thumb {
  height: 40px;
  width: 40px;
}
.bigThumb {
  height: 11em;
  width: 11em;
  max-width: 90%;
  margin: 0 auto;
}
.image {
  background-color: #999;
  background-size: cover !important;
  background-position: center;
}
.Gallery {
  height: 100%;
}
