.verticalTable {
  width: 90% !important;
  margin: auto;
  h5 {
    margin: 3px;
    padding-left: 10px;
    font-size: 14px;
  }
  .content {
    color: #555;
    border-bottom: 1px solid #ccc;
    padding: 6px;
    text-align: center;
  }
  .image {
    width: 50% !important;
    height: 200px !important;
    border-radius: 0px !important;
    margin: 0 auto;
    display: block;
  }
}
