.login {
  width: auto;
  margin: auto;
  display: block;
  width: fit-content;
  padding: 55px;
  height: fit-content;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9px;

  button {
    width: 100%;
    i span {
      margin: 15px;
    }
  }
  form {
    height: fit-content;
  }
  .avatar {
    background: white;
    text-shadow: inset 1px 1px 2px #007777;
  }

  input {
    text-shadow: 1px 1px 1px black;
    text-align: center;
  }
  label {
    color: black !important;
    text-shadow: 1px 1px 2px #e1f1f1;
    font-size: 1rem;
  }
  .avatar {
    margin: 0 auto;
    text-align: center;
  }
  h1 {
    text-align: center;
  }
  .submit {
    display: block;
    margin: auto;
    border-radius: 9px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 10px;
    width: 99%;
    height: 100%;
    input {
      font-size: 1rem;
    }
    form {
      display: grid;
      height: fit-content;
      margin: 10px;
    }
  }
}

.loginBackground:before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  content: "";
  filter: blur(4px);
  background-size: cover;
  background-position: center;
}
.loginBackground {
  height: 100vh;
}
