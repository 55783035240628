.userPreview {
  color: #444;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  .imageContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column;
  }
  .username {
    font-weight: bolder;
    text-transform: uppercase;
  }
  .subtext {
    color: #999;
    font-style: italic;
    font-size: 12px;
  }

  .actions {
    .fabutton {
      .title {
        display: none;
      }
    }
  }
}
.MuiMenu-paper {
  i {
    margin: 5px;
  }
}
