.modalContainer {
  overflow: auto;

  display: flex;
  flex-flow: column;
  justify-content: center;
}
.modal {
  margin: 0 auto;

  > div,
  > form {
    align-content: center;
  }
  .closeModal {
    position: absolute;
    top: 0;
    left: 97% !important;
    z-index: 99;
  }
  .prompt {
    background: white;
    border-radius: 9px;
    padding: 5px;
    overflow: hidden;
    .promptInput {
      margin: 0;
    }
    .buttons {
      float: right;
    }
  }
}
@media only screen and (max-width: 768px) {
  .modal {
    width: 100% !important;
    .closeModal {
      left: 90vw !important;
    }
  }
}
.fullscreenModal {
  position: fixed;
  padding: 3vw;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
