.iconicCard {
  padding: 15px;
  max-width: 350px;
  height: 320px;
  margin: 10px auto;
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  z-index: 1;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 50px 20px #99999999;
    transform: scale(1.1);
    z-index: 99;
    .iconContainer {
      box-shadow: inset 5px 5px 9px #d5d5d59f, inset -5px -5px 9px #ffffffa4;
    }
  }
  .iconContainer {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    border-radius: 100%;
    text-align: center;
    margin: 0 auto;
    color: white;
    font-weight: bolder;
  }
  h3 {
    font-size: 5rem;
    font-weight: bolder;
    text-align: center;
    margin: 0px;
    height: 100px;
    line-height: 100px;
    text-shadow: 19px 18px 9px #f1f1f1;
  }
  .subtitle {
    text-align: center;
    p {
      color: rgb(136, 125, 125);
      font-weight: bolder;
      font-size: 1rem;
    }
  }
}
