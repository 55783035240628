.account {
  align-items: center;
  justify-content: center;
  margin: 15px;
  .InfoData {
    cursor: pointer;
  }
  .profileContainer {
    display: flex;
  }
  .profile {
    display: block;
    align-self: center;
    position: relative;
    top: -25px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 300px;
    max-width: 100%;
  }
  .value {
    background-color: #eee;
    border-radius: 40px;
    padding-left: 15px;
  }
  .editableInput {
    height: 80px;
  }
  h4 {
    margin: 5px;
    font-size: 12px !important;
    color: #444;
  }
}
