.panelContainer {
  padding: 15px;
  .gradientBg {
    margin-top: -20px;
    padding: 15px;
    border-radius: 5px;
    width: calc(100% - 80px) !important;
    margin: 0 auto;
    position: relative;
    top: -30px;
    box-shadow: 2px 4px 3px #999;
    color: white;
  }
}
form {
  width: 500px;
  max-width: 100%;
  .gridItem {
    margin: 15px;
  }
  .ql-toolbar.ql-snow,
  .ql-editor .ql-blank,
  .quill {
    border: none;
  }
  .ql-container.ql-snow {
    border: none;
    border-bottom: 1px solid #eee !important;
  }
}
