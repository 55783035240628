@import url("https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap");
.frontContainer {
  background-color: #fafafa;
  position: absolute;
  top: 0;
  left: 0;
  width: stretch;
  padding: 15px;

  height: fit-content;
}
.panelContainer .fabutton {
  padding: 5px;
  .title {
    margin: 10px;
  }
}
.MuiIconButton-sizeSmall {
  width: fit-content;
}
.MuiIconButton-root {
  border-radius: 40px !important;
}
form {
  margin: 0 auto;
}
.backContainer {
  min-height: 91vh;
  a {
    text-transform: capitalize;
  }
}
header {
  overflow: hidden;
}
.left {
  box-shadow: -54px 20px 31px #0000002e;
  left: 260px;
  top: 10px;

  min-height: 100vh;
  input {
    display: hidden;
  }
}
* {
  transition: all 0.6s linear;
}
.actionMenu {
  display: flex;
}
.main {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
}
.hidden {
  display: none;
}
.customHeader {
  width: 88%;
  margin: 21px auto;
  display: block;
  .gridItem {
    &:last {
      float: left;
    }
    padding: 15px;
  }
}
.content {
  margin: 0 auto;
  width: 88%;
}
.errorMessage {
  color: red;
}
