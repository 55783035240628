.singularPost {
  margin: 0 auto;
  .InfoData {
    margin: 5px;
    padding: 15px;
    background: white;
    box-shadow: 1px 1px 5px #eee;
    border-radius: 9px;
  }
  .descriptionArea {
    .toggleInput {
      min-height: 600px;
    }
    .value {
      padding: 5%;
    }
    .quill {
      height: 500px;
    }
  }
  .toggleInput {
    cursor: pointer;
  }

  label {
    width: 100% !important;
  }
}
.active {
  > .gridContainer {
    background: #00000009;
  }
}

.backdrop {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .fullscreen {
    height: 80vh;
    display: block;
    background-size: contain !important;
    width: 90vw;

    margin: 10px;
  }
}
.validatorPost {
  background: white;
  min-height: 80vh;
  .imagesContainer {
    background: black;
    img {
      margin: 0 auto;
    }
  }
  .sideContainer {
    padding: 15px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    .buttons {
      align-self: flex-end;
    }
    .counter {
      text-align: center;
    }
  }
}
.postPreview {
  color: #444;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  .imageContainer {
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin: 0 auto;
    height: 300px;
    width: 100%;
  }

  .postData,
  .username {
    padding: 10px;
  }
  .username {
    text-align: center;
  }
  .subtext {
    font-size: 0.7rem;
  }
  button {
    .title {
      display: none;
    }
  }
}

@media only screen and (min-width: 991px) {
  .imageContainer {
    width: 30px !important;
    height: 30px !important;
  }
}
