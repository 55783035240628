.profileGrid {
  .title {
    display: none;
  }
}
.catSelector {
  .saveContainer {
    button {
      margin-top: 15px;
    }
    i {
      font-size: 2.8rem;
    }
  }
}
